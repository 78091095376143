/* Bootstrap overrides
-------------------------------------------------- */

$body: #161619;
$headings-font-weight: 300 !important;
$primary: #1b46c2;
$light: #fafafa;

$headings-font-weight: 300 !important;
$a: #1392ff;

// Custom Var
$maincol1: #e34756 !important;
$maincol2: #151517;
$maincol3: #262526;
$maincol4: #3e3a3a;
$maincol5: #dcc7a6;
$maincol6: #fbc9a7;
// $maincol7: #948e8e;
$maincol7: #e1e1e1;
$maincol8: #E9D2AF;
$chevz-right: url("../../assets/img/arrow-right.svg");

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';


@import 'font.scss';


.text-s1 {
  font-size: 1rem;
}

.text-s1-1 {
  font-size: 1.1rem;
}

.text-s1-2 {
  font-size: 1.2rem;
}

.color-white {
  color: #fff;
}

.color-main1 {
  color: $maincol1;
}

.color-main2 {
  color: $maincol2;
}

.color-main3 {
  color: $maincol3;
}

.color-main4 {
  color: $maincol4;
}

.color-main5 {
  color: $maincol5;
}

.color-main6 {
  color: $maincol6;
}

.color-main7 {
  color: $maincol7;
}

.color-main8 {
  color: $maincol8;
}

.shadow,
.t-shadow {
  text-shadow: 1px 1px 2px black;
}


body {
  font-family: 'Open Sans', "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  font-size: 1.6rem;
  overflow: initial !important;
  padding-right: 0 !important;
}

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}


/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {

    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    //margin-top: 0.5rem;
    font-size: 1rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: $maincol1;
        }
      }
    }
  }

  .footer-separator {
    margin-left: -15px;
    width: 100%;
    height: 15px;
    background: url('../../assets/img/separate.svg') center center no-repeat;
  }
}


/* Custom AOZ */
body {
  background: linear-gradient(to bottom, #161517, #161619);
}

.container {
  position: relative;
}

// For toaster
.dapp-core-ui-component {
  .transactions-toast-list_wrapper {
    z-index: 100;
    bottom: 2rem;
    right: 1rem;
    position: fixed;
    background: transparent !important;
    transform: scale(1.2);
    margin-right: 1rem;

    .transactions-toast-list_title {
      font-size: 0.9rem;
    }
  }

  .icon-state {
    border-radius: 50%;

    svg {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      max-height: 100%;
      overflow: hidden;
    }
  }

  .transactions-toast-list_toastFooter .tx-details_trimContainer {
    width: 10rem;
    overflow: hidden;
    height: 10px;
    padding-top: 4px;
  }
}

.wrapper-container {
  position: relative;
}

.mainz.__mainz-context-default:not(:empty),
.__mainz-context--marketplace,
.__mainz-context--land {
  margin-top: -150px;
}

.mainz.__mainz-context--my-account,
.__mainz-context--Tos,
.__mainz-context--privacy-policy,
.__mainz-context--jobs--id {
  margin-top: -150px;
  position: relative;
}

.mainz.__mainz-context--my-account::before,
.__mainz-context--Tos::before,
.__mainz-context--privacy-policy::before,
.__mainz-context--jobs--id::before,
.__mainz-context--id::before,
.__mainz-context-marketplace-id-create::before,
.__mainz-context--marketplace--id::before,
.__mainz-context--id-create::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgb(162, 158, 135);
  background: linear-gradient(0deg, rgba(62, 58, 58, 0.9) 0%, rgba(0, 0, 0, 1) 100%);
  // background: linear-gradient(0deg, rgba(162,158,135,0.908000700280112) 0%, rgba(0,0,0,1) 100%);
}

.mp-container,
.macc-container,
.tos-container,
.privacy-container {
  padding: 15rem 0;
}

.mainNav {
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  position: relative;
  padding: 20px 0;
  // padding-left: 2rem;

}

.mainNav .navbar-toggler {
  font-size: 80%;
  padding: 0.75rem;
  color: $maincol1;
  border: 1px solid $maincol1;
}

.mainNav .navbar-toggler:focus {
  outline: none;
}

.mainNav .navbar-brand {
  color: #000;
  font-weight: 700;
  padding: 0.9rem 0;
}

.mainNav .navbar-nav .nav-item {
  flex: 1;
}

.mainNav .navbar-nav .nav-item:hover {
  color: fade(#fff, 80%);
  outline: none;
  background-color: transparent;
  // @media screen and (max-width: 1200px) {
  //   .navbar-nav{
  //   padding-left: 100px;
  //   }
  // }
}

.mainNav .navbar-nav .nav-item:active,
.mainNav .navbar-nav .nav-item:focus {
  outline: none;
}

.nav-item-custom {
  cursor: pointer;
  position: relative;
  //padding: 0rem 4rem; not necessary with most menu items
}

.navbar-nav {
  padding-left: 32px;
}

@media screen and (max-width: 1050px) {
  .navbar-nav {
    padding-left: 62px;
  }
}

.navbar-light .navbar-nav .nav-link {
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  padding: 2rem 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;

  &.nav-link-disabled {
    cursor: normal;
  }

  .nav-tooltip {
    display: none;
  }
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  padding: 2rem 1.5rem;
  color: rgba(255, 255, 255, 0.9);

  .nav-tooltip {
    z-index: 10;
    display: block;
    padding: 1rem 0.5rem;
    background: $maincol2;
    width: 80%;
    position: absolute;
    left: 0;
    border-radius: 3px;
    font-size: 0.8rem;
    transform: translateX(-5%);
    color: #FFF;
    font-weight: 600;
    text-align: center;
    margin-top: 0.6rem;

    &:before {
      content: "";
      position: absolute;
      top: -14px;
      left: 50%;
      margin-left: -4px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $maincol2 transparent;
    }
  }
}

.navbar-light .navbar-nav .nav-link.active {
  padding: 2rem 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.navbar-light .navbar-nav .nav-link:hover:before,
.navbar-light .navbar-nav .nav-link.active:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid black;
  transform: rotate(45deg);
  left: 0;
  top: 50%;
  margin-top: -5px;
}

.footer-nav-container .navbar-light .navbar-nav .nav-link.active:before,
.footer-nav-container .navbar-light .navbar-nav .nav-link:hover:before {
  // left: initial;
  width: 8px;
  height: 8px;
  margin-top: -4px;
}

.nav-item-head {
  background-position: left 40%;
  background-repeat: no-repeat;
  background-image: $chevz-right;
}

.section-page-head {
  cursor: pointer;
  position: relative;
  font-size: 1.8rem;
  padding-left: 2.4rem;
}

.section-page-head-loading {
  cursor: pointer;
  position: relative;
  font-size: 1.8rem;
  padding-left: 2.4rem;
}

.section-page-head::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  ;
  top: 0.75rem;
  left: 0;
  position: absolute;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: $chevz-right;
  background-size: contain;
}

.section-page-head-loading::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  ;
  top: 0.75rem;
  left: 0;
  position: absolute;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/spinner.svg");
  background-size: contain;
}

.section-item {
  position: relative;
  padding-left: 2.4rem;

  &::before {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    left: 0;
    position: absolute;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.section-item-kosons::before {
    background-image: url("../../assets/img/Koson_Symbol.png");
  }

  &.section-item-wolf::before {
    background-image: url("../../assets/img/logotyp-white.png");
  }

  .section-page-content {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }

  &.expanded {
    .section-page-head::before {
      transform: rotate(90deg);
    }

    .section-page-content {
      height: initial;
      visibility: initial;
      transition: 0.5s;
    }
  }
}

.nav-item-head .nav-link {
  font-size: 1.6rem !important;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9) !important;
  opacity: 1 !important;
  border-bottom: 1px solid transparent !important;
}

.overlap-content-d {
  .nav-link {
    padding: 0.5rem 0;
    margin: 0 0 0 1.5rem;
  }
}

.zalmoxis-logo {
  width: 60px;
  height: 60px;
  margin: auto;
  display: block;
}

.maiar-logo {
  position: relative;
  height: 28px;
  width: 28px;
}

// .maiar-logo-container::after {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: $maincol2;
//   border-radius: 50%;
//   height: 32px;
//   width: 32px;
//   margin-top: 1.7rem;
//   margin-left: 1.2rem;
//   opacity: 0.5;
// }
.wrapper-container {
  position: relative;
}

.cus-dropdown {
  padding: 0 1.5rem;
}

.cus-btn {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}

.cus-btn:hover {
  color: rgba(255, 255, 255, 0.75);
}

.body-wrapper {
  min-width: 100%;
  padding: 0;
}

.masthead {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  //padding: 15rem 0;
  padding-bottom: 5rem;
  background: url("../../assets/img/bg-header.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  .masthead-btn {
    justify-content: space-around;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      justify-content: center;
      flex-direction: column;
    }
  }

  .container {
    height: 100%;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      height: 120%;
      justify-content: start !important;
      padding-top: 6rem;
    }
  }
}

.w-33 {
  width: 33.3333%;
}

.masthead-no-bg-land-chests {
  position: relative;
  width: 100%;
  height: 330px;
  min-height: 200px;
  padding-bottom: 1rem;
  margin-top: 120px;
}

.masthead-no-bg-team {
  position: relative;
  width: 100%;
  height: 100px;
  min-height: 100px;
  padding-bottom: 1rem;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width:768px) and (max-width: 1199.98px) {
  .masthead .container {
    padding-top: 12rem !important;
  }
}

.marketplace-page {
  .container {
    height: 100%;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      height: 120%;
      justify-content: start !important;
      padding-top: 11rem;
    }
  }

  @media (min-width:768px) and (max-width: 1199.98px) {
    .masthead .container {
      padding-top: 17rem !important;
    }
  }
}

.filters-modal {
  font-size: 1.2rem;

  .toggle {
    label {
      transition: 0.5s;
      cursor: pointer;

      &:before {
        content: '';
        background: url('./../../assets/img/left-tg.svg') center center no-repeat;
        background-size: contain;
        width: 40px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
      }
    }

    input {
      display: none;

      &:checked {
        ~label {
          color: $maincol6;

          &:before {
            background: url('./../../assets/img/right-tg.svg') center center no-repeat;
          }
        }
      }
    }
  }
}

.masthead-mscroll {
  margin-top: 5.5rem;

  svg {
    height: 40px;
  }
}

// TODO l'image du header ne contient plus la roadmap donc pas besoin de mettre un bloc qui entour head + rm
//.masthead-with-rm {
//  background: url("../../assets/img/bg-header.svg");
//  background-position: top center;
//  background-repeat: no-repeat;
//  background-attachment: scroll;
//  background-size: cover;
//}

.rm-container {
  margin-top: -100px;
  padding: 15rem 0;
  background: url("../../assets/img/bg-roadmap.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    padding-top: 500px;
  }
}

.section-title {
  background: url('../../assets/img/bg-add-after.png') center center no-repeat;
  background-size: contain;
  height: 100px;
  line-height: 75px;

  .bg-rm {
    background: url('../../assets/img/bg-add-after.png') center center no-repeat;
  }

  .bg-presale {
    background: url('../../assets/img/bg-add-after-presale.png') center center no-repeat;
  }

  .bg-faq {
    background: url('../../assets/img/bg-add-after-faq.png') center center no-repeat;
  }

  &:after {
    content: '';
    background: url('../../assets/img/active-add-bottom.svg') center center no-repeat;
    height: 30px;
    width: 100%;
    display: block;
    margin-top: -20px;
  }
}

.listz {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;

  .itemz {
    display: block;
    cursor: pointer;
    width: 100%;
    list-style: none;
    padding: 1rem 0;

    .itemz-img {
      width: 30px;
      background: url('../../assets/img/logo.svg') center center no-repeat;
      background-size: 20px;
      opacity: 0.3;
    }

    .itemz-content {
      width: calc(100% - 30px - 30px);
      font-size: 0.8rem;
      padding: 0 1rem;

      .itemz-content-title {
        font-weight: 600;
      }

      .itemz-content-authoring {}
    }

    .itemz-action {
      width: 30px;
      font-weight: 600;
      opacity: 0.2;
      text-align: center;
    }

    a {
      color: #ffffff;

      &:hover,
      &:focus,
      &:visited {
        text-decoration: none;
        color: transparent;
      }
    }

    &.active,
    &:hover {
      .itemz-img {
        opacity: 1;
      }

      .itemz-content {
        .itemz-content-title {
          padding-left: 15px;
          position: relative;

          &:before {
            content: '';
            background: url('../../assets/img/add.svg') center center no-repeat;
            width: 10px;
            height: 22px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      .itemz-action {
        opacity: 1;
        background: url('../../assets/img/more.svg') center center no-repeat;
        color: transparent;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transition: 0.3s;
        }
      }
    }
  }
}

.btz {
  &.disabled {
    opacity: 0.6;
  }

  .outer {
    z-index: 2;
    display: inline-block;
    // width: 180px;
    width: 200px;
    // height: 40px;
    height: 50px;
    position: relative;
    color: #fafafa;
    text-align: center;
    font-size: 1rem;
    font-weight: 800;
    // line-height: 40px;
    line-height: 50px;
    cursor: pointer;
    background: url("../../assets/img/button.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
  }
}

.btz:not(.disabled) .outer:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.btz-sm {
  transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transform: scale(0.55);
}

.btz-sm .outer {
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold !important;
}

h2 {
  color: #ffffff;
  font-size: 1.8rem;
}

.card {
  background: #151518;
  color: #fafafa;
  border-radius: 0.1rem !important;
}

.card-wrapper .card {
  min-width: 300px;
  // overflow: hidden;
}

.card-trans {
  background: transparent;
}

.card-light {
  position: relative;
  background: #5b5b5b;
  background: url('../../assets/img/presale-bg.svg') center center no-repeat;
  background-size: cover;
}


// .card-light::before {
//   content: "";
//   background: url("../../assets/img/before-bg.png"), url("../../assets/img/after-bg.png");
//   background-position: left top, left bottom;
//   background-repeat: no-repeat;
//   width: calc(100% + 18px);
//   position: absolute;
//   height: calc(100% + 18px);
//   margin-top: -32px;
//   left: 0;
// }

// .card-light::after {
//   content: "";
//   background: url("../../assets/img/before-bg-y.png"), url("../../assets/img/after-bg-y.png");
//   background-position: left top, right top;
//   background-repeat: no-repeat;
//   width: calc(100% + 18px);
//   position: absolute;
//   height: calc(100% + 18px);
//   margin-bottom: -8px;
//   margin-left: -7px;
//   bottom: 0;
//   left: 0;
// }

.card-light-head {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  margin-top: -35px;
  background: url("../../assets/img/top-sign.svg");
  background-position: center top;
  background-repeat: no-repeat;
}


.nav {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  font-size: 1rem;
  color: #ffffff;
  opacity: 0.6;
  font-weight: 800;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.nav-tabs .nav-link.disabled {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-item.show .nav-link {
  cursor: pointer;
  position: relative;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-bottom: 1px solid transparent;
  background: transparent;
}

.nav-tabs .nav-link.active::after,
.nav-tabs .nav-link:hover::after,
.nav-tabs .nav-item.show .nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: rgba(255, 255, 255, 1);
}

.nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.progress-rm {
  height: 7px !important;
  background: #151518;
  overflow: initial;
}

.progress-rm .progress-bar {
  height: 4px !important;
  margin-top: 1px;
  position: relative;
  overflow: initial;
  background: rgba(255, 255, 255, 0.6);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  -webkit-transform: scaleX(0.99);
  transform: scaleX(0.99);
}

.progress-rm .progress-bar:after {
  position: absolute;
  right: -12px;
  content: "";
  background: white;
  border: 3px solid #151518;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.cardz-2 {
  background: saddlebrown;
}

.bgz-2 {
  background: #2c3034;
}

.overlap-content {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -10px;
  transform: translateY(50%);
  -ms-transform: translateY(50%);
  -webkit-transform: translateY(50%);

  .overlap-content-g,
  .overlap-content-d {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    height: 320px;
    margin: 1rem;
  }

  .nav-tabs {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      white-space: initial;
    }

    .nav-item-head {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

.rm-info {
  padding-bottom: 2px;
  padding-left: 2px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.rm-dot {
  position: relative;
  width: 5px;
  height: 5px;
  display: block;
  background-color: white;
  border-radius: 50%;
  margin-top: 1rem;
}

.rm-dot::after {
  position: absolute;
  top: 5px;
  left: 2px;
  display: block;
  content: "";
  width: 1px;
  height: 10px;
  background-color: #37373c;
}

.rm-text-block {
  cursor: pointer;
  position: relative;

  .rm-tooltip {
    display: none;
  }

  &.active {
    @include media-breakpoint-down(sm) {
      color: $maincol1;
      position: relative;

      .rm-tooltip {
        display: block;
        padding: 1rem;
        background: $maincol2;
        position: absolute;
        top: 125px;
        left: 0;
        border-radius: 3px;
        font-size: 0.8rem;
        color: #FFF;
        font-weight: 600;
        text-align: left;
        width: 500%;
        transform: translateX(-40%);

        &:before {
          content: "";
          position: absolute;
          top: -14px;
          left: 50%;
          margin-left: -4px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent transparent $maincol2 transparent;
        }
      }

      .rm-tooltip-title {
        font-weight: 600;
      }

      .rm-tooltip-description {
        font-weight: normal;
        opacity: 0.4;
      }
    }
  }

  &:not(.disable):hover {
    color: $maincol1;
    position: relative;

    .rm-tooltip {
      display: block;
      padding: 1rem;
      background: $maincol2;
      width: 200%;
      position: absolute;
      top: 125px;
      left: 0;
      border-radius: 3px;
      font-size: 0.8rem;
      transform: translateX(-25%);
      color: #FFF;
      font-weight: 600;
      text-align: left;

      @include media-breakpoint-down(sm) {
        width: 500%;
        transform: translateX(-40%);
      }

      &:before {
        content: "";
        position: absolute;
        top: -14px;
        left: 50%;
        margin-left: -4px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent $maincol2 transparent;
      }

      .rm-tooltip-title {
        font-weight: 600;
      }

      .rm-tooltip-description {
        font-weight: normal;
        opacity: 0.4;
      }
    }
  }

  &.disable {
    opacity: 0.2;
  }

  .rm-text {
    position: relative;
    padding-left: 20px;
    font-size: 1rem;

    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  .rm-text:hover .rm-info {
    visibility: visible;
  }
}


.rm-block {

  // frist two
  .rm-text-block:nth-child(-n + 2) {
    .rm-tooltip {
      @include media-breakpoint-down(sm) {
        transform: translateX(-10%);
      }

      &:before {
        @include media-breakpoint-down(sm) {
          left: 15%;
        }
      }
    }
  }

  // last two
  .rm-text-block:nth-last-child(-n + 2) {
    .rm-tooltip {
      @include media-breakpoint-down(sm) {
        transform: translateX(-70%);
      }

      &:before {
        @include media-breakpoint-down(sm) {
          left: 80%;
        }
      }
    }
  }
}

.presale-container {
  background: $maincol2;
  overflow: hidden;
}

.faq-container {
  background: $maincol3;
  padding-bottom: 5rem;

  .faq-questions {
    width: 100%;

    .faq-question {
      width: 100%;
      font-size: 1.2rem;
      padding: 1.2rem 3.5rem;
      margin: 1rem 0;
      background: url('../img/bg-faq-bottom.svg') no-repeat center center;
      background-size: contain;

      .faq-question-default {
        display: flex;
        align-content: center;
        justify-content: space-between;

        .faq-question-title {
          cursor: pointer;
        }

        .faq-question-expand {
          width: 30px;
          font-weight: 600;
          text-align: center;
          opacity: 1;
          background: url('../../assets/img/more.svg') center center no-repeat;
          color: transparent;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
            -ms-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            transition: 0.3s;
          }
        }
      }

      .faq-question-description {
        display: none;
      }

      &.expanded {
        padding: 1rem 2.5rem;
        background: url('../img/bg-faq-bottom.svg') top center no-repeat, url('../img/bg-faq-bottom.svg') bottom center no-repeat;
        background-size: contain;

        .faq-question-default {
          padding: 0 1rem;
        }

        .faq-question-expand {
          opacity: 0.2;
          z-index: 10;

          &:hover {
            transform: none;
          }
        }

        .faq-question-description {
          background: #141415;
          padding: 1.5rem 3.5rem 1rem 3.5rem;
          margin-left: -2.5rem;
          margin-top: -0.75rem;
          margin-bottom: 0.75rem;
          width: calc(100% + 5rem);
          opacity: 1;
          display: block;
          color: rgba(255, 255, 255, 0.5);
          overflow: hidden;
        }
      }

      @media screen and (max-width: 992px) {
        background: rgb(20, 20, 21);

        &.expanded {
          background: rgb(20, 20, 21);
        }
      }
    }
  }
}

.footer-nav-container {
  background: $maincol4;
  position: relative;
  padding-top: 2rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -27px;
    height: 30px;
    width: 100%;
    background: url('../../assets/img/top-bg-footer.svg') center center no-repeat;
  }

  .go-top {
    content: '';
    height: 50px;
    width: 50px;
    background: url('../../assets/img/go-top.svg') center center no-repeat;
    background-size: cover;
    position: absolute;
    top: -30px;
    left: calc(50% - 28px);
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      -ms-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transition: 0.3s;
    }
  }

  .copyright {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .copyright-peggy {
      width: 40px;
      height: 40px;
      background: url('../../assets/img/16.svg') center center no-repeat;
      background-size: contain;
    }

    .copyright-content {
      text-align: left;
      padding-left: 1rem;
      line-height: 10px;

      .copyright-content-title {
        font-weight: 600;
        width: 100%;
        margin: 0;
        padding: 0.05rem;
        font-size: 0.9rem;
      }

      .copyright-content-details {
        margin: 0;
        width: 100%;
        font-weight: 600;
        opacity: 0.5;
        padding: 0.05rem;
        font-size: 0.9rem;
      }
    }
  }

  .end-footer {
    background: #161619;
    width: 100%;
    padding: 2rem 0;

    .footer-links {
      flex: 1;

      .footer-links-separator {
        vertical-align: middle;
        display: inline-block;
        width: 10px;
        height: 10px;
        padding: 0 1rem;

        @include media-breakpoint-down(sm) {
          padding: 0 0.1rem;
        }

        background: url('../../assets/img/separator.svg') center center no-repeat;
      }

      .footer-links-element {
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;

        .nav-link {
          padding: 0
        }

        @include media-breakpoint-down(sm) {
          margin: 0.25rem
        }
      }
    }

    .logos-partners,
    .logo-brand {
      flex: 1;
      height: 35px;
      padding: 0 0.75rem;
    }

    .container {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      div {
        @include media-breakpoint-down(sm) {
          margin: 1.25rem
        }
      }
    }
  }

  .logo-wenmoon {
    flex: 1;
    height: 40px;
  }

  .logo-ayartis {
    flex: 1;
    height: 28px;
    margin-left: 0.5rem;
  }
}

.presale-container {
  position: relative;
}

.presale-container::before {
  content: "";
  background: url("../../assets/img/before-bg.png");
  background-position: center center;
  background-repeat: repeat-x;
  background-attachment: scroll;
  background-size: cover;
  width: 100%;
  position: absolute;
  height: 18px;
  margin-top: -8px;
}

.presale-container::after {
  content: "";
  background: url("../../assets/img/after-bg.png");
  background-position: center center;
  background-repeat: repeat-x;
  background-attachment: scroll;
  background-size: cover;
  width: 100%;
  position: absolute;
  height: 18px;
  margin-bottom: -7px;
  bottom: 0;
}

.presale-round-title {
  position: relative;
  color: #fff;
  padding-left: 20px;
  font-size: 1rem;
  margin-top: 1.5rem;
}

.presale-round-title::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: $maincol2;
  transform: rotate(45deg);
  left: 0;
  top: 50%;
  margin-top: -4px;
}

.presale-head {
  position: relative;
  font-size: 1.6rem;
  color: #ffffff;
}

.presale-head::before {
  content: "";
  width: 18px;
  position: absolute;
  height: 18px;
  left: -20px;
  top: 6px;
  background-position: left top;
  background-repeat: no-repeat;
  background-image: $chevz-right;
  background-size: contain;
}

.cnt-block {
  max-width: 90%;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    max-width: 100%;
  }

  .cnt-text-block {
    white-space: nowrap;
    background: url('../../assets/img/timer-bg.svg') center center no-repeat transparent;
    padding: 1.5rem;

    @include media-breakpoint-down(sm) {
      margin: 0 0.1rem;
      padding: 1.25rem 1rem;
    }

    .cnt-numb {
      font-family: 'Open Sans Extra Bold';
      font-weight: bold;
      font-size: 1.95rem;
    }

    .cnt-text {
      font-weight: bold;
      font-size: 0.85rem;
    }
  }
}

.presale-msg {
  font-size: 1rem;
}

.progress-sale {
  height: 14px !important;
  background: #292526;
  overflow: initial;
  border-radius: 50px;
}

.progress-sale .progress-bar-sale {
  height: 12px !important;
  margin-top: 1px;
  position: relative;
  overflow: initial;
  background: linear-gradient(to right, #292526, #918888);
  border-radius: 50px;
  -webkit-transform: scaleX(0.98);
  transform: scaleX(0.98);
}

.egld-sym {
  width: 18px;
  margin: 0 4px;
}

.social-links {
  @include media-breakpoint-down(md) {
    display: none;
  }

  position: fixed;
  top: 34%;
  left: 7%;
  z-index: 10;

  a {
    margin: 2rem 0;
  }

  a:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }

  .social-fb {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/facebook.svg') center center no-repeat transparent;
    background-size: contain;
  }

  .social-tg {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/telegram-icon.svg') center center no-repeat transparent;
    background-size: contain;
  }

  .social-tw {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/twitter.svg') center center no-repeat transparent;
    background-size: contain;
  }

  .social-yt {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/youtube.svg') center center no-repeat transparent;
    background-size: contain;
  }

  .social-di {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../assets/img/discord.svg') center center no-repeat transparent;
    background-size: contain;
  }
}

.gototop-fixed {
  @include media-breakpoint-down(md) {
    display: none;
  }

  cursor: pointer;
  position: fixed;
  top: 94%;
  right: 5%;
  z-index: 10;
  display: block;
  width: 70px;
  height: 70px;
  background: url('../../assets/img/gototop.svg') center center no-repeat transparent;
  background-size: contain;
  transition: 0.3s;
  opacity: 1;
}

.gototop-fixed:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.gototop-fixed.in-bottom {
  opacity: 0;
}

.connect-container {
  text-align: left;
  padding: 1rem 2.5rem;
  background: #171718;

  .card {
    background: #171718;
    border: none;
  }

  .connect-title {
    font-size: 1.8rem;
    padding-left: 15px;
    position: relative;
    padding-bottom: 1rem;

    &:before {
      content: '';
      background: url('../../assets/img/add.svg') center center no-repeat;
      width: 10px;
      height: 38px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .connect-elements {
    .connect-element {
      height: 70px;
      width: 100%;
      padding: 0.5rem 0;
      font-size: 1rem;
      display: flex;
      align-content: center;
      font-weight: 600;

      &:hover {
        cursor: pointer;

        .connect-element-content {
          background: #232123;
        }
      }

      .connect-element-image {
        width: 60px;
        background: url('../img/more.svg') center center no-repeat;
        background-size: contain;
        z-index: 2;
      }

      .connect-element-content {
        height: 100%;
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(to right, #232123, #171718);
        margin-left: -25px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: calc(1rem + 25px);

        .connect-element-title {}

        .connect-element-description {
          opacity: 0.5;
        }
      }
    }
  }

  .dapp-core-ui-component {
    visibility: hidden;
    display: none;
    height: 0 !important;
    width: 0 !important;
  }
}

#accessWalletBtn svg {
  display: none;
}

.wallect-connect-login_qrCodeSvgContainer {
  border: 5px solid white;
  border-radius: 5px;
}

.container-main-ftlogo-block {
  //margin-bottom: 10rem; TODO
}

.container-main-ftlogo-text {
  @include media-breakpoint-down(lg) {
    width: auto !important;
  }

  .text-resp {
    text-align: left;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}

.container-main-ftlogo-rpad {
  @include media-breakpoint-down(lg) {
    display: none;
  }

}

.rm-container {
  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }
}

.tiny-secondary-link {
  color: #696969;
  font-size: 1rem;

  span {
    cursor: pointer;
  }
}

.logo-aoz-fulltext {
  width: 100%;
  max-width: 520px;
}

.mainNav {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mainMobileNav {
  text-align: center;
  display: none;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 2;
  padding-top: 0;
  position: relative;

  .menu-items {
    padding-top: 2rem;
    height: 0px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;

    .nav-item,
    .nav-link {
      position: relative;
      padding: 1rem 0;
      cursor: pointer;
      color: #fff;
    }

    .nav-link:hover:before {
      position: absolute;
      right: -16px;
      content: "";
      background-position: left 50%;
      background-repeat: no-repeat;
      background-image: $chevz-right;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      left: 0;
      top: 40%;
    }

    .nav-link:hover:after {
      position: absolute;
      right: -16px;
      content: "";
      transform: rotate(180deg);
      background-position: left 50%;
      background-repeat: no-repeat;
      background-image: $chevz-right;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      right: 0;
      top: 40%;
    }
  }

  &.open {
    background: #161618;
  }

  &.open .menu-items {
    height: auto;
    transition: 0.3s;
    height: 100vh;
    overflow: initial;
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  .menu-btn {
    cursor: pointer;
  }

  .social-links {
    position: relative;
    display: flex;
    flex-direction: row;

    a {
      margin: 0.5rem 1rem;

      @include media-breakpoint-down(sm) {
        margin: 0.1rem 0.45rem
      }
    }
  }
}

.zalmoxis-logo-mobile-nav {
  margin: 0;
}

.footer-nav-container {
  .mainMobileNav {
    display: none;
  }

  .footer-separator {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.masthead {
  @include media-breakpoint-down(sm) {
    height: 60vh;
  }
}

.listz-container {
  @include media-breakpoint-down(sm) {
    overflow: auto;
  }
}


.sepz {
  display: block;
  padding-left: 2px;
  height: 12px;
  background: #4f4b4c;

}

.balances {
  font-size: 1rem;
  justify-content: left;

  .k-balance {
    min-width: 25%;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column !important;
  }

  .sepz {
    margin: 0 3rem;

    @include media-breakpoint-down(md) {
      margin: 0 1rem;
    }
  }

  .btz {
    margin-left: 2rem;

    @include media-breakpoint-down(sm) {
      margin-left: initial;
      margin-top: 1rem;
    }
  }

  .cntz {
    margin-left: 2rem;

    @include media-breakpoint-down(sm) {
      margin-left: initial;
      margin-top: 0.5rem;
    }
  }
}

.e-balance .dappui-balance .symbol {
  display: none;
}

.account-wallet-wrapper,
.referal-wrapper {
  width: 100%;
  font-size: 1rem;
  background: #262426;
  padding: 1.25rem 0.75rem;
  border-radius: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-wallet-container,
.referal-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .sepz {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .acc-addr,
  .acc-ref-text {
    @include media-breakpoint-down(md) {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0 !important;
      display: flex;
      flex-direction: column;
    }
  }

  .btn-gen-act {
    margin-left: 2.5rem;

    @include media-breakpoint-down(md) {
      margin-left: 0.5rem;
    }
  }
}

.main-separator {
  margin-left: -7px;
  width: 100%;
  height: 15px;
  background: url('../../assets/img/separate.svg') center center no-repeat;
}

.logout-container {
  justify-content: end;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.dapp-core-ui-component {

  // bottom: 1rem;
  //   right: 1rem;
  //   position: fixed;
  .btn-light {
    color: #ffffff;
    background-color: $maincol2;
    border-color: $maincol2;
  }


  .modal-content,
  .card,
  .toast-messages,
  .toast {
    background: $maincol2 !important;
  }

  .toast {
    .progress {
      &.position-relative {
        height: auto;
        font-size: 0.6rem;
        background: $maincol2 !important;
        border: 1px solid white;

        .progress-bar {
          opacity: 0.1;
          background-color: #4a4b4e;
        }
      }
    }
  }
}

.custom-class-for-modals_iconContainer {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50px;

  svg.custom-class-for-modals_icon {
    padding: 10px;
    width: 30px;
    height: 30px
  }
}

.marketplace-page,
.keystats-page,
.macc-container,
.view-offer-page,
.marketplace-listings-page,
.team-page,
.job-page,
.land-sale-page {
  .masthead-search {
    font-size: 0.9rem;
    max-width: 350px;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(21, 21, 23, 0.6);
    border-radius: 3rem 0 0 3rem;
    padding: 1rem 50px 1rem 1.5rem;
    font-weight: bolder;
    position: relative;
    transform: translateX(-20px);

    &:after {
      content: '';
      display: block;
      position: absolute;
      padding: 0;
      margin: 0;
      right: -23px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 23px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 23px solid rgba(21, 21, 23, 0.6);
    }

    .label {
      width: fit-content;
    }

    .separator {
      margin-left: -15px;
      line-height: 15px;
      width: 10px;
      height: 10px;
      padding: 0 1rem;

      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
      }

      background: url('../../assets/img/separator.svg') center center no-repeat;
    }

    input {
      width: auto;
      flex: auto;
      padding: 0;
      background: transparent;
      border: none;
      color: #FFF;
    }

    button {
      position: absolute;
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: url('../../assets/img/btn-more-empty.svg') center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
      z-index: 10;

      svg {
        width: 13px;
        height: 13px;
        background: transparent;
        stroke: red;
        color: #FFF;
        margin-top: 7px;
        box-shadow: 0 0 10px rgba(183, 56, 68, 0.1);
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
        -ms-transform: translateY(-50%) scale(1.05);
        -webkit-transform: translateY(-50%) scale(1.05);
        transition: 0.3s;
      }
    }
  }

  .container-light {
    background: #1d1c1e;
  }

  .features-container,
  .my-nfts-container,
  .offer-container,
  .open-for-trade-container,
  .join-us-container,
  .other-jobs-container {
    padding: 5rem 0;
    padding-top: 0rem !important;

    .bloc-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5rem 0;

      .bloc-head-title {
        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 1rem;
          background: url('../../assets/img/arrow-right.svg') no-repeat center center;
          background-size: cover;
        }

        button {
          background: url("../../assets/img/button.svg") no-repeat center center;
          background-size: contain;
          border: none;
          font-size: 0.9rem;
          font-weight: bolder;
          color: #FFF;
          padding: 0.5rem 2rem;
          white-space: nowrap;

          &:hover {
            transform: scale(1.05);
            -ms-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            transition: 0.3s;
          }
        }
      }

      .bloc-head-action {
        width: 40px;
        height: 40px;
        background: url('../../assets/img/more.svg') center center no-repeat;
        background-size: cover;
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transition: 0.3s;
        }
      }
    }

    .bloc-content {

      .sticky {
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        top: 0;
        z-index: 9;
      }

      .bloc-content-element {
        position: relative;
        min-height: 350px;
        height: 100%;
        padding: 0.75rem;
        background: #262426;
        border-radius: 5px;

        .balance {
          font-size: 1rem;

          .text-content-pre {
            opacity: 0.6;
          }
        }

        .bloc-content-element-img {
          height: auto;
          width: 100%;
          // background: #151517 url('../../assets/img/no-image.png') no-repeat center center;
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          text-align: center;

          img {
            height: auto;
            width: 100%;
            overflow: hidden;
          }

          .bloc-content-element-img-tag {
            position: absolute;
            top: 0.75rem;
            right: 0.75rem;
            font-size: 0.8rem;
            font-weight: 600;
            background: #565458;
            color: #FFF;
            border-radius: 3px;
            padding: 0 0.5rem;
            line-height: 18px;

            &.red {
              background: #b23642;
            }
          }

          .bloc-content-element-img-tag-left {
            position: absolute;
            top: 0.75rem;
            left: 0rem;
            font-size: 0.8rem;
            font-weight: 600;
            background: #565458;
            color: #FFF;
            border-radius: 3px;
            padding: 0 0.5rem;
            line-height: 18px;

            &.red {
              background: #b23642;
            }
          }


          .bloc-content-element-img-new {
            position: absolute;
            top: 0.75rem;
            left: 0.75rem;
            font-size: 0.8rem;
            font-weight: 600;
            background: #00886a;
            color: #FFF;
            border-radius: 3px;
            padding: 0 0.5rem;
            line-height: 18px;
          }
        }

        .bloc-content-element-content {
          padding: 1rem;

          .bloc-content-element-content-head {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-bottom: 0.75rem;

            .bloc-content-element-content-head-title {
              cursor: pointer;
              font-weight: bolder;
              font-size: 1.2rem;
              color: #FFF;
            }

            .bloc-content-element-content-head-id {
              font-size: 0.9rem;
              line-height: 1.6rem;
            }
          }

          .bloc-content-element-content-price {
            display: inline-block;
            font-size: 1rem;
            padding-right: 1rem;
            color: $maincol6;
            font-weight: bolder;
          }

          .bloc-content-element-content-description {
            display: inline-block;
            font-size: 0.9rem;
          }
        }

        &.dark {
          background: #141416;

          .bloc-content-element-img {
            background: #262426 url('../../assets/img/no-image.png') no-repeat center center;
          }

          .bloc-content-element-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            @include media-breakpoint-down(sm) {
              flex-direction: column;
              padding: 0 0.5rem !important;
              justify-content: start;
              align-items: start;
            }

            .sepz {
              @include media-breakpoint-down(sm) {
                visibility: hidden;
                opacity: 0;
              }
            }
          }
        }

        &.element-tops {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: auto;
          padding: 0.75rem 1.5rem;

          .bloc-content-element-number {
            width: 40px;
            height: 40px;
            background: url('../../assets/img/btn-more-empty.svg') center center no-repeat;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 50%;
            text-align: center;
            font-size: 0.8rem;
            font-weight: bolder;
            line-height: 30px;
            transform: translateX(-50%) translateY(-50%);
            padding-top: 5px;
          }

          .bloc-content-element-content {
            padding: 0 1.5rem;

            .bloc-content-element-content-head {
              margin-bottom: 0;
            }

            .bloc-content-element-content-elrond-logo {
              display: inline-block;
              vertical-align: -0.5px;

              svg {
                height: 15px;
                width: auto;
                padding-right: 0.5rem;
              }
            }
          }

          .bloc-content-element-img {
            height: 70px;
            width: 70px;

            img {
              height: 100%;
            }
          }

          .bloc-content-element-action {
            right: 1rem;
            left: initial;
            top: 50%;
            bottom: initial;
            transform: translateY(-50%);

            &:hover {
              transform: translateY(-50%) scale(1.05);
              -ms-transform: translateY(-50%) scale(1.05);
              -webkit-transform: translateY(-50%) scale(1.05);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  .my-nfts-container {
    padding-top: 1rem;
  }

  .overflow-initial {
    overflow: initial;
  }

  .summoning-ritual-element {
    font-size: 0.8rem;
    padding: 3rem 1rem;
    text-align: center;
    background: #2b292b;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    .summoning-ritual-element-number {
      width: 30px;
      height: 30px;
      background: url('../../assets/img/btn-more-empty.svg') center center no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 0.8rem;
      font-weight: bolder;
      line-height: 30px;
      transform: translateX(-50%) translateY(-50%);
    }

    .summoning-ritual-element-plus {
      font-size: 2.5rem;
      width: 35px;
      height: 35px;
      border: 3px solid #5d5b5a;
      font-weight: bolder;
      border-radius: 50%;
      color: #5d5b5a;
      margin: 0 auto 0.5rem auto;
      line-height: 27px;
    }
  }

  .summoning-ritual-text {
    font-size: 1rem;

    .first-text {
      font-size: 1.2rem;
    }

    .symbol svg {
      height: 20px;
      width: auto;
    }

    .colored {
      color: $maincol6;
    }

    button {
      background: url("../../assets/img/button.svg") no-repeat center center;
      background-size: contain;
      border: none;
      font-size: 0.9rem;
      font-weight: bolder;
      color: #FFF;
      padding: 0.5rem 2rem;
      white-space: nowrap;

      &:hover {
        transform: scale(1.05);
        -ms-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transition: 0.3s;
      }
    }
  }

}

.view-offer-page {
  background: #1d1c1e;

  .bloc-img {
    height: 100%;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .bloc-img-verified {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 20px;
      height: 20px;
      background: url('../../assets/img/verified.svg') center center no-repeat;
      background-size: contain;
    }

    img {
      border-radius: 5px;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      &.empty {
        background: #151517 url('../../assets/img/no-image.png') no-repeat center center;
      }
    }
  }

  .bloc-description {
    padding: 1rem;

    .bloc-description-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bloc-description-head-title {
        font-size: 2rem;
        font-weight: bolder;
        padding-right: 1rem;
        display: inline-block;
      }

      .bloc-description-head-id {
        font-size: 1rem;
        font-weight: bolder;
        background: #262426;
        padding: 0 0.5rem;
        opacity: 0.8;
        width: fit-content;
        width: -moz-fit-content;
        border-radius: 5px;
        display: inline-block;
        vertical-align: 3px;
      }

      .bloc-description-head-action {
        color: $maincol6;
        font-size: 1rem;
        text-decoration: underline;
        font-weight: bolder;
      }
    }

    .bloc-description-subhead {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bloc-description-subhead-key {
        font-size: 1rem;
        opacity: 0.8;
      }

      .bloc-description-subhead-tag {
        font-size: 0.8rem;
        font-weight: 600;
        background: #565458;
        color: #FFF;
        border-radius: 3px;
        padding: 0 0.5rem;
        line-height: 18px;
      }
    }

    .bloc-description-description {
      font-size: 1rem;
      opacity: 0.8;
      margin-top: 1.5rem;
    }

    .bloc-description-separator {
      width: 100%;
      border-color: #FFF;
      opacity: 0.05;
      margin: 2.5rem 0;
    }

    .bloc-description-metas {
      font-size: 1rem;
      opacity: 0.8;
      padding-left: 1rem;

      li {
        padding: 0.5rem 0;

        &::marker {
          color: $maincol6;
        }

        .bloc-description-metas-item {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .bloc-description-metas-key {}

          .bloc-description-metas-value {
            &.text-colored {
              color: $maincol6;
              font-weight: bolder;
            }
          }
        }
      }
    }
  }

  .bloc-head {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 1.5rem 0;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .bloc-head-title {
      line-height: 15px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 1rem;
        background: url('../../assets/img/arrow-right.svg') no-repeat center center;
        background-size: cover;
      }
    }

    .bloc-head-separator {
      margin-left: -15px;
      line-height: 15px;
      width: 10px;
      height: 10px;
      padding: 0 1.5rem;

      @include media-breakpoint-down(sm) {
        padding: 0 0.1rem;
      }

      background: url('../../assets/img/separator.svg') center center no-repeat;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .bloc-head-subtitle {
      line-height: 12px;
      opacity: 0.8;
      font-size: 1rem;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 2rem;
        padding-top: 0.75rem;
      }
    }
  }

  .bloc-content {
    padding: 2rem 0;
    width: 100%;
  }

  .faq-questions {
    width: 100%;

    .faq-question {
      width: 100%;
      font-size: 1.2rem;
      padding: 1.2rem 3.5rem;
      margin: 1rem 0;
      background: url('../img/bg-faq-bottom.svg') no-repeat center center;
      background-size: contain;

      .faq-question-default {
        display: flex;
        align-content: center;
        justify-content: space-between;

        .faq-question-title {
          cursor: pointer;
        }

        .faq-question-expand {
          width: 30px;
          font-weight: 600;
          text-align: center;
          opacity: 1;
          background: url('../../assets/img/more.svg') center center no-repeat;
          color: transparent;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
            -ms-transform: scale(1.05);
            -webkit-transform: scale(1.05);
            transition: 0.3s;
          }
        }
      }

      .faq-question-description {
        display: none;
      }

      &.expanded {
        padding: 1rem 2.5rem;
        background: url('../img/bg-faq-bottom.svg') top center no-repeat, url('../img/bg-faq-bottom.svg') bottom center no-repeat;
        background-size: contain;

        .faq-question-default {
          padding: 0 1rem;
        }

        .faq-question-expand {
          opacity: 0.2;
          z-index: 10;

          &:hover {
            transform: none;
          }
        }

        .faq-question-description {
          background: #141415;
          padding: 1.5rem 3.5rem 1rem 3.5rem;
          margin-left: -2.5rem;
          margin-top: -0.75rem;
          margin-bottom: 0.75rem;
          width: calc(100% + 5rem);
          opacity: 1;
          display: block;
          color: rgba(255, 255, 255, 0.5);
          overflow: hidden;
        }
      }

      @media screen and (max-width: 992px) {
        background: rgb(20, 20, 21);

        &.expanded {
          background: rgb(20, 20, 21);
        }
      }
    }
  }

  .bloc-attribute {
    background: #151416;
    border-radius: 1rem;
    text-align: center;
    font-size: 1rem;
    padding: 1rem;
    font-weight: 600;

    .bloc-attribute-title {
      color: $maincol6;
    }

    .bloc-attribute-subtitle {
      padding-bottom: 0.5rem;
    }

    .bloc-attribute-description {
      opacity: 0.6;
    }
  }

  .bloc-offer {
    background: #141415;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 1.5rem 2rem;
    border-radius: 1rem;

    .bloc-offer-number {
      width: 30px;
      height: 30px;
      background: url('../../assets/img/btn-more-empty.svg') center center no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 1rem;
      font-weight: bolder;
      line-height: 30px;
      transform: translateX(-50%) translateY(-50%);
    }

    .bloc-offer-img {
      width: 80px;
      height: 80px;
      background: #1d1c1e;
      border-radius: 1rem;
    }

    .bloc-offer-content {
      padding: 0 3rem;
      font-size: 1rem;

      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
      }

      .separator {
        margin-left: -15px;
        vertical-align: middle;
        display: inline-block;
        width: 10px;
        height: 10px;
        padding: 0 2rem;

        @include media-breakpoint-down(sm) {
          padding: 0 0.1rem;
        }

        background: url('../../assets/img/separator.svg') center center no-repeat;
      }

      .colored {
        color: $maincol6;
      }
    }
  }
}

.section-page-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  .bloc-head-separator {
    margin-left: -15px;
    line-height: 15px;
    width: 10px;
    height: 10px;
    padding: 0 1.5rem;

    @include media-breakpoint-down(sm) {
      padding: 0 0.1rem;
    }

    background: url('../../assets/img/separator.svg') center center no-repeat;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .bloc-head-subtitle {
    line-height: 20px;
    opacity: 0.8;
    font-size: 1rem;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0rem;
      padding-top: 0.75rem;
    }
  }
}


.bloc-content-element-action {
  background: url("../../assets/img/button.svg") no-repeat center center;
  background-size: contain;
  border: none;
  font-size: 0.9rem;
  font-weight: bolder;
  color: #FFF;
  padding: 0.5rem 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%) translateY(50%);

  &.light {
    background: url("../../assets/img/button-light.svg") no-repeat center center;
    background-size: contain;
    padding: 4rem 3rem;
    color: $maincol2;
  }

  &.disabled {
    opacity: 0.6;
  }

  &:hover {
    transform: translateX(-50%) translateY(50%) scale(1.05);
    -ms-transform: translateX(-50%) translateY(50%) scale(1.05);
    -webkit-transform: translateX(-50%) translateY(50%) scale(1.05);
    transition: 0.3s;
  }

  &.rel {
    position: relative;
    bottom: initial;
    left: initial;
    transform: initial;

    &:hover:not(.disabled) {
      transform: scale(1.05);
      -ms-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transition: 0.3s;
    }
  }

  &.small {
    width: 35px;
    height: 35px;
    background: url('../../assets/img/more.svg') center center no-repeat;
    background-size: contain;
  }
}

.copy-link {
  cursor: pointer;
  opacity: 0.7;
  text-decoration: underline;
}

.create-offer-page {
  .bloc-head {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 1.5rem 0;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .bloc-head-title {
      line-height: 15px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 1rem;
        background: url('../../assets/img/arrow-right.svg') no-repeat center center;
        background-size: cover;
      }
    }
  }

  .bloc-content {
    padding: 2rem 0;

    .form-label {
      font-size: 0.9rem;
      font-weight: 600;
      padding: 1.5rem 0 0.75rem 0.5rem;
    }

    select {
      //position: absolute;
      //top: 50%;
      //right: 2rem;
      //transform: translateY(-50%) rotate(90deg);
      //width: 30px;
      //height: 30px;
      background: url('../../assets/img/arrow-bottom.svg') calc(100% - 2rem) center no-repeat !important;
      //z-index: 999;
    }

    .form-control {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid #262426;
      background-color: #161618;
      padding: 0 2rem;
      height: 50px;
      border-radius: 1rem;
      color: #FFF;
      font-size: 0.9rem;

      &.border-highlight {
        border-color: #565156;
      }

      &.colored {
        border-color: #262426;
        background-color: #262426;
        color: $maincol6;
      }
    }
  }

  .bloc-content-element {
    position: relative;
    min-height: 350px;
    padding: 0.75rem;
    background: #262426;
    border-radius: 5px;

    .bloc-content-element-img {
      height: 300px;
      width: 100%;
      // background: #151517 url('../../assets/img/no-image.png') no-repeat center center;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      text-align: center;

      img {
        height: 100%;
        overflow: hidden;
      }

      .bloc-content-element-img-tag {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        font-size: 0.8rem;
        font-weight: 600;
        background: #565458;
        color: #FFF;
        border-radius: 3px;
        padding: 0 0.5rem;
        line-height: 18px;

        &.red {
          background: #b23642;
        }
      }

      .bloc-content-element-img-new {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        font-size: 0.8rem;
        font-weight: 600;
        background: #00886a;
        color: #FFF;
        border-radius: 3px;
        padding: 0 0.5rem;
        line-height: 18px;
      }
    }

    .bloc-content-element-content {
      padding: 1rem;

      .bloc-content-element-content-head {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 0.75rem;

        .bloc-content-element-content-head-title {
          cursor: pointer;
          font-weight: bolder;
          font-size: 1.2rem;
          color: #FFF;
        }

        .bloc-content-element-content-head-id {
          font-size: 0.9rem;
          line-height: 1.6rem;
        }
      }

      .bloc-content-element-content-price {
        display: inline-block;
        font-size: 1rem;
        padding-right: 1rem;
        color: $maincol6;
        font-weight: bolder;
      }

      .bloc-content-element-content-description {
        display: inline-block;
        font-size: 0.9rem;
      }
    }

    .bloc-content-element-action {
      background: url("../../assets/img/button.svg") no-repeat center center;
      background-size: contain;
      border: none;
      font-size: 0.9rem;
      font-weight: bolder;
      color: #FFF;
      padding: 0.5rem 2rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);

      &:hover {
        transform: translateX(-50%) translateY(50%) scale(1.05);
        -ms-transform: translateX(-50%) translateY(50%) scale(1.05);
        -webkit-transform: translateX(-50%) translateY(50%) scale(1.05);
        transition: 0.3s;
      }
    }
  }
}

.breadcrumb {
  background: none;
  font-size: 1rem;
  width: 100%;
  padding: 0;

  a,
  a:hover,
  a:focus,
  a:visited {
    color: #FFF;
    text-decoration: none;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    margin-right: 0.5rem;
    content: '>';
    background: url('../../assets/img/arrow-right.svg') center center no-repeat;
    color: transparent;
    opacity: 0.5;
  }

  .breadcrumb-item {
    opacity: 0.8;

    &.active {
      opacity: 1;
      color: #FFF;
      font-weight: bolder;
    }
  }
}

.open-for-trade-container,
.join-us-container,
.job-container {
  .bloc-content-element {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;

    .bloc-content-element-action {
      width: 135px;
      position: relative !important;
      top: initial !important;
      left: initial !important;
      right: initial !important;
      bottom: initial !important;
      transform: initial !important;
      -webkit-transform: initial !important;

      &:hover {
        transform: scale(1.05) !important;
        -webkit-transform: scale(1.05) !important;
        -ms-transform: scale(1.05) !important;
      }

      &.light {
        background-size: cover;
        padding: 1rem 3rem;
      }
    }

    .last-head-column {
      width: calc(150px - 28px);
    }

    .last-column {
      width: calc(150px);
    }
  }

  .separator {
    margin-left: -15px;
    line-height: 15px;
    width: 10px;
    height: 10px;
    padding: 0 1rem;

    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
    }

    background: url('../../assets/img/separator.svg') center center no-repeat;
  }
}

.colored {
  color: $maincol6;
}

.flex-basis-0 {
  flex-basis: 0;
}

.selectable {
  border: 0px;
}

.selectable:hover {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.selected {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 5px #9ecaed;
}

.team_member {
  position: relative;
  background: rgb(38, 36, 38);
  font-size: 1rem;

  .team_member_socials {
    position: absolute;
    z-index: 1;
    top: 0.7rem;
    right: 1rem;
    display: flex;
    flex-direction: column;

    .team_member_socials_item {
      width: 25px;
      height: 25px;
      background: #FFF;
      color: #000;
      margin: 0.25rem 0;
      font-size: 0.5rem;
      line-height: 46px;
      border-radius: 50%;

      &.linkedin {
        background: #0e76a8;
        color: #FFF;
      }

      &.twitter {
        color: #1DA1F2;
      }

      &.twitch {
        color: #6441a5;
        background: transparent;
        line-height: 50px;
      }
    }
  }

  .team_member_img {
    opacity: 0.8;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    box-shadow: 0 20px 27px -20px rgba(255, 255, 255, 0.5);

    &.empty {
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        background-color: #000;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        padding: 2rem;
        opacity: 0.3;
      }
    }
  }

  .team_member_infos_role {
    font-size: 0.8rem;
  }
}

.land-sale-page {
  font-size: 1.2rem;
  line-height: 22px;

  .bloc-land-chest {
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 30px);
      background: #151517;
      transform: skew(350deg, 0);
      box-shadow: 0 29px 40px -20px #fbc9a715;
      opacity: 0;
      transition: 0.5s;
    }

    .bloc-land-chest_title {
      font-size: 1.3rem;
      font-weight: 600;
    }

    .bloc-land-chest_available {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .no-hover {
      display: block;
    }

    .with-hover {
      display: none;
    }

    &:hover,
    &.active {
      .no-hover {
        display: none;
      }

      .with-hover {
        display: block;
      }

      .bloc-land-chest_title,
      .bloc-land-chest_available {
        text-shadow: #fbc9a7 0 0 10px;
      }
    }

    &.active {
      &:before {
        opacity: 1;
      }
    }
  }

  .attributes {
    font-size: 2rem;

    b {
      color: $maincol6;
    }
  }

  .description {
    b {
      color: $maincol6;
    }
  }

  .highlight {
    text-shadow: #fbc9a7 0 0 10px;
  }

  .land-content {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    align-items: center;

    .purchase-container {
      display: flex;
      align-items: center;

      .price {
        display: flex;

        .symbol {
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .bloc-content-element-action {
        position: relative;
        top: initial;
        bottom: initial;
        left: initial;
        right: initial;
        transform: initial;
      }
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  .land-plot-img {
    animation: floating 12s linear 0s infinite;
  }

  .land-chest-attributes {
    padding-left: 0;

    .description {
      font-size: 1.5rem;
    }
  }

  @keyframes lightning {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .land-chest-img-light {
    position: relative;
    padding: 0;
    margin-top: 7rem;

    .with-light {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      opacity: 0;
      animation: lightning 6s linear 0s infinite;
    }
  }

  .land-custom-4 {
    ul.land-chest-attributes li.description:last-child {
      padding-bottom: 1.6rem;
    }
  }
}

.purchase-container {
  justify-content: space-around;
  flex-direction: row;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .price {
    margin: 2rem 0;
  }

  .qty {
    position: relative;
    margin: 2rem 0;

    & .before,
    & .after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    & .before {
      background: url('../img/arrow-top.png') no-repeat center center;
      top: -15px;
    }

    & .after {
      background: url('../img/arrow-bottom.png') no-repeat center center;
      bottom: -15px;
    }
  }

  .input-qty {
    background-color: rgb(37, 33, 34);
    color: white;
    font-size: medium;
    max-width: 4rem;
    padding: 0.25rem 0.5rem;
    border: none;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.uTrust-logo {
  svg {
    position: absolute;
    margin-top: -10px;
    height: 20px;
    width: 120px;

    @include media-breakpoint-down(sm) {
      position: relative;
      margin-bottom: 10px;
    }
  }
}

.show-more-container {
  .show-more-part {
    display: none;
  }

  &.expanded {
    .btz-wrapper-container {
      display: none;
    }

    .show-more-part {
      display: block;
    }
  }
}

.combined-custom-container {
  margin-top: 5rem;
}

.text-custom-land-chests {
  margin-top: 8rem;

  @include media-breakpoint-down(lg) {
    margin-top: 6rem;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}


.footer-nav-container {
  .maiar-logo-container {
    @include media-breakpoint-down(lg) {
      width: 76px;
    }

    &.anom {
      width: 152px;

      @include media-breakpoint-down(lg) {
        width: 118px;
      }
    }
  }
}

.keystats-page {
  .bloc-table-row {
    border-radius: 0.75rem;
    overflow: hidden;

    .bloc-table {
      padding: 1px;

      .bloc-table-content {
        background: #272425;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 1rem;

        .bloc-table-title {
          color: #FFF;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 2px;
          white-space: nowrap;

          a {
            color: #FFF;
          }
        }

        .bloc-table-value {
          background: linear-gradient(to right, #1e1e20, #242224);
          color: $maincol6;
          padding: 0.15rem 0.5rem;
          border-radius: 5px;
        }

        .bloc-table-cus {
          width: 20%;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .bloc-table-cus2 {
          width: 40%;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
  }

  .section-page-head {
    padding-left: 0;

    &:before {
      display: none;
    }

    .bloc-head-title {
      font-size: 1.5rem;
      line-height: 15px;
    }

    .bloc-logo-aoz {
      background: url('../../assets/img/logotyp-white.png') no-repeat center center;
    }

    .bloc-logo-koson {
      background: url('../../assets/img/Koson_Symbol.png') no-repeat center center;
    }

    .bloc-chevron-right {
      background: url('../../assets/img/arrow-right.svg') no-repeat center center;
    }

    .bloc-logo-aoz,
    .bloc-logo-koson {
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    .bloc-chevron-right {
      width: 10px;
      height: 10px;
      background-size: contain;
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: rgb(182, 169, 159);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.stake-pool {
  padding: 5px;
  background-color: rgba(38, 36, 38, 1);
  border-radius: 10px !important;
}

.stake-pool-body {
  background-color: rgba(50, 48, 50, 0.8);
  border-radius: 10px !important;
}

.stake-pool-header {
  background-color: rgba(38, 36, 38, 1);
  margin-left: 10px;
  margin-right: 10px;
}

.stake-pool-item {
  padding: 10px !important;
  border-radius: 10px !important;
}

.loading {
  display: inline-block;
  font-size: 12px;
  clip-path: inset(0 1.2ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}