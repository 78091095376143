body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bid-input {
  text-align: center;
  border-radius: 8px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Open Sans', "Helvetica Neue", Arial, sans-serif;
}

/* .modalElements{
display:flex;
flex-direction: row;
align-items: center;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NFT swap */

[type='radio'] {
  display: none;
}

.nft-selector-container {
  overflow: scroll;
}